import React from 'react';
import Input from '../../Input';
import { Flex, Box } from 'rebass';
import { Formik, Form, Field } from 'formik';
import Select from '../../Select';
import Text from '../../Text';
import TextArea from '../../TextArea';
import Button from '../../Button';
import { inputFields, topics } from './fields.yaml';

const RequestInformationForm = ({ onSend }) => {
  return (
    <Formik onSubmit={onSend} initialValues={{}}>
      <Form>
        <Flex flexDirection="column">
          {inputFields.map((input, idx) => (
            <Box p={1} key={`field-${idx}`}>
              <Field name={input.label}>
                {({ field }) => (
                  <Input
                    width={1}
                    {...input}
                    label={`${input.label}${input.required ? '*' : ''}`}
                    {...field}
                  />
                )}
              </Field>
            </Box>
          ))}
        </Flex>
        <Box p={1}>
          <Text variant="secondary" mb={2}>
            <label htmlFor="subject-select">
              I Would Like Information on the Following*
            </label>
          </Text>
          <Field name="I would like information on the following">
            {({ field }) => (
              <Select required id="subject-select" {...field}>
                <option value="">Select a topic</option>
                {topics.map(topic => (
                  <option key={topic} value={topic}>
                    {topic}
                  </option>
                ))}
              </Select>
            )}
          </Field>
        </Box>
        <Box width={1} p={1}>
          <Field name="Additional comments">
            {({ field }) => (
              <TextArea
                width={1}
                rows="10"
                cols="20"
                label="Additional comments"
                {...field}
              />
            )}
          </Field>
        </Box>
        <Button width={1} mt={2} type="submit">
          Send
        </Button>
      </Form>
    </Formik>
  );
};

RequestInformationForm.propTypes = {};

export default RequestInformationForm;
