import React, { useState } from 'react';
import axios from 'axios';
import SEO from '../../components/seo';
import PageLayout from '../../components/Layout/Page';
import Spinner from '../../components/Spinner';
import { Flex } from 'rebass';
import RequestInformationForm from '../../components/RequestInformation/Form';
import RequestInformationConfirmation from '../../components/RequestInformation/Confirmation';
import Alert from '../../components/Alert';

const netlifyEndpoint = '/.netlify/functions/send-mail';

const RequestInformationPage = () => {
  const [didSend, setDidSend] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const sendMail = e => {
    setLoading(true);
    const { Name, Email, Subject, ...rest } = e;
    const message = ['Name', 'Email', ...Object.keys(rest)]
      .map(key => `${key}: \n${e[key]}\n\n`)
      .join('');
    const body = {
      name: Name,
      email: Email,
      subject: `BBSH Request for information: "${Subject}"`,
      message,
    };
    axios
      .post(netlifyEndpoint, body)
      .then(data => {
        if (data.status === 200) {
          setDidSend(true);
        }
        setLoading(false);
      })
      .catch(e => {
        setError(e);
        setLoading(false);
      });
  };
  return (
    <PageLayout maxWidth={640} title="Request information">
      <SEO title="Request information" />
      {didSend ? (
        <RequestInformationConfirmation />
      ) : (
        <RequestInformationForm onSend={sendMail} isLoading={isLoading} />
      )}
      {isLoading && (
        <Flex mt={3} justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {error && (
        <Alert mt={3} type="danger">
          {error?.message || error || 'An unexpected error occurred'}
        </Alert>
      )}
    </PageLayout>
  );
};

export default RequestInformationPage;
