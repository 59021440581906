import React from 'react';
import Text from '../../Text';
import { Flex } from 'rebass';
import Button from '../../Button';
import { Link } from 'gatsby';

const RequestInformationConfirmation = () => {
  return (
    <Flex flexDirection="column" alignItems="center" py={[4, 5, 6]}>
      <Text fontSize={3} textAlign="center" mb={3}>
        📨
        <br />
        Your message has been sent! We will get back to you soon.
      </Text>
      <Link to="/">
        <Button variant="outline">Back to home</Button>
      </Link>
    </Flex>
  );
};

RequestInformationConfirmation.propTypes = {};

export default RequestInformationConfirmation;
